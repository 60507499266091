import { RedocStandalone } from "redoc";
import "./App.css";
import redocOptions from "./redocOptions";
import spec from "./open-api.json";
import { ReactComponent as Logo } from "./showell-logo-white.svg";

function App() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          background: "#0d26ac",
          height: 85,
          display: "flex",
          padding: 10,
          paddingLeft: 20,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Logo style={{ width: 200 }} />
      </div>
      <RedocStandalone spec={spec} options={redocOptions} />
    </div>
  );
}

export default App;
