export default {
  colors: {
    primary: { main: "#138ee5" },
    warning: { main: "#ffbf06" },
    success: { main: "#1e3cd2" },
    error: { main: "#da4167" },
  },
  typography: {
    fontFamily: "Inter",
    headings: {
      fontFamily: "Poppins",
      fontWeight: "500",
      lineHeight: "1.6em",
    },
  },
  rightPanel: {
    backgroundColor: "#1e3cd2",
  },
};
